import { Api, JsonRpc } from 'eosjs';
import { JsSignatureProvider } from 'eosjs/dist/eosjs-jssig';           // development only
import { Buffer } from 'buffer';


import logo from './logo.svg';
import './App.css';

// @ts-ignore
// IMPORTANT: set Buffer for library eosjs: Uncaught (in promise) ReferenceError: Buffer is not defined
window.Buffer = Buffer;


// const rpcEndpoint = 'https://testnet.waxsweden.org';

const rpcEndpoint = 'https://waxtest.eu.eosamsterdam.net';

//const rpcEndpoint = '135.181.206.121:8888';
const rpc = new JsonRpc(rpcEndpoint, { fetch });

const taixiu = "gamingofdice";
const taixiuKey = "5J63bNBrAe8yEb8MBim5ZgNQoZQD7GJCcDJJzjwWhcayUfwwjzf";

const baucua = "waxpscbaucua";
const baucuaKey = "5JnXQcjocPKZzuyq9bHp4RWZstrRxz8A4PCmnNYBB9cT8FLE6BE";

const xocdia = "waxpscxocdia";
const xocdiaKey = "5Kd9CcjEqZCGSQ7Gpn5EhmJpD8LjCitHfYRTdg6LRvhX9D9XHLt";

const rocket = "crashtrenbal";
const rocketKey = "5HqJqnt4sF1o2RVSK8g1eYZdSKhwHPg5wSGScpwJnnbjToYN38J";

const baccarat = "waxpbaccarat";
const baccaratKey = "5KXBEghEZ9uEenDSio2qp5JoG8WTFNxqR8V2zCh7Y7UQ9iuCDR7";

const dealer = "dealerwamwam";
const dealerKey = "5JwpncFFdxmGCNVbhuMrvzqQJrrfwgRygGgh5iQBtLN16WLmhmG";

function setPause (user, value) {
  const transact = {
    actions: [{
      account: user,
      name: 'setpause',   // ACTION BET
      authorization: [{
        actor: user,   // User Wallet Address (Wax Cloud Wallet)
        permission: 'active',
      }],
      data: {
        pause: value
      },
    }]
  };
  return transact;
}

export async function sendTransaction ( privateKey, transact ) {
  const signatureProvider = new JsSignatureProvider([privateKey]);
  const api = new Api({ rpc, signatureProvider, textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });

  try {
    await api.transact(transact, {blocksBehind: 3,expireSeconds: 30,}) .then((result) => {
      console.log(result);
    });
  } catch (error) {
    console.log('\nCaught exception: ' + error);
  }
}

function setGameLottery (smartcontract) {
  const transact = {
    actions: [{
      account: smartcontract,
      name: 'setgame',   // ACTION BET
      authorization: [{
        actor: dealer,   // User Wallet Address (Wax Cloud Wallet)
        permission: 'active',
      }],
      data: {},
    }]
  };
  return transact;
}

function App() {

  function pause (value) {
    sendTransaction(taixiuKey, setPause(taixiu, value));
    sendTransaction(baucuaKey, setPause(baucua, value));
    sendTransaction(xocdiaKey, setPause(xocdia, value));
    sendTransaction(rocketKey, setPause(rocket, value));
    sendTransaction(baccaratKey, setPause(baccarat, value));
  }
  
  
  
  
  function startLottery () {
    sendTransaction(dealerKey, setGameLottery("waxpsclodeld"));
    sendTransaction(dealerKey, setGameLottery("waxpscmaxmax"));
    sendTransaction(dealerKey, setGameLottery("waxpsclotwax"));
  }

  return (
    <div className="App">
      <header className="App-header">
      <button onClick={() => pause(true)} type='button' >PAUSE</button>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <button onClick={() => pause(false)} type='button' >RUN</button>
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <button onClick={() => startLottery()} type='button' >START LOTTERY</button>
      </header>
    </div>
  );
}

export default App;
